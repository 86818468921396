@import './colors.scss';
@import './variables.scss';
@import './pods.scss';

.leftIndent {
  margin-left: 7px !important;
}

.marginTop {
  margin-top: 7px !important;
}

.saveButton {
  color: white !important;
  background-color: rgba(0, 128, 0, 0.7) !important;
  border-radius: 0 !important;
  font-family: 'Archivo Narrow' !important;
  font-size: 16px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important;
  font-weight: 600 !important;
  padding: 10px !important;
  text-transform: unset !important;

  &:hover {
    background-color: rgba(0, 128, 0, 0.7) !important;
  }

  &:disabled {
    background-color: $disabled-button-color !important;
  }
}

.purpleButton {
  color: white !important;
  background-color: $medium-font-color !important;
  border-radius: 0 !important;
  font-family: 'Archivo Narrow' !important;
  letter-spacing: 0.5px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  padding: 10px !important;
  text-transform: unset !important;

  &:hover {
    background-color: rgba(110, 54, 148, 0.7) !important;
  }

  &:disabled {
    background-color: $disabled-button-color !important;
  }
}

.cancelButton {
  color: $medium-font-color !important;
  background-color: rgba(224, 213, 234, 1) !important;
  border-radius: 0 !important;
  font-family: 'Archivo Narrow' !important;
  letter-spacing: 0.5px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  padding: 10px !important;
  text-transform: unset !important;

  &:hover {
    background-color: rgba(224, 213, 234, 0.7) !important;
  }

  &:disabled {
    background-color: $disabled-button-color !important;
  }
}

.emailButton {
  color: $medium-font-color !important;
  background-color: rgba(224, 213, 234, 1) !important;
  border-radius: 0 !important;
  font-family: 'Archivo Narrow' !important;
  letter-spacing: 0.5px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  padding: 10px !important;
  text-transform: unset !important;
  text-decoration: none !important;

  img {
    margin: -4px 4px -4px;
  }

  &:hover {
    background-color: rgba(224, 213, 234, 0.7) !important;
  }

  &:disabled {
    background-color: $disabled-button-color !important;
  }
}

.deleteButton {
  color: white !important;
  background-color: rgba(239, 64, 68, 1) !important;
  border-radius: 0 !important;
  font-family: 'Archivo Narrow' !important;
  letter-spacing: 0.5px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  padding: 10px !important;
  text-transform: unset !important;

  &:hover {
    background-color: rgba(239, 64, 68, 0.7) !important;
  }

  &:disabled {
    background-color: $disabled-button-color !important;
  }
}

.goldButton {
  color: white !important;
  background-color: #efac42 !important;
  border-radius: 0 !important;
  font-family: 'Archivo Narrow' !important;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 16px !important;
  letter-spacing: 0.5px;
  padding: 10px !important;
  text-transform: unset !important;

  &:hover {
    background-color: rgba(239, 172, 66, 0.7) !important;
  }

  &:disabled {
    background-color: $disabled-button-color !important;
  }
}

input {
  border-radius: 0;
}
